<template>
  <v-container fluid fill-height class="bg">
    <Navbar />
    <v-container fluid>
      <v-row justify="center" align="center">
        <v-col cols="12" class="d-flex justify-center">
          <div>
            <brand></brand>
          </div>
        </v-col>
        <v-col md="6" cols="12" class="px-0">
          <v-card elevation="6">
            <profile-form title="Seu cadastro" :editable="true" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import ProfileForm from "../components/auth/ProfileForm.vue";
import Brand from "../components/global/Brand.vue";
export default {
  components: { Navbar, ProfileForm, Brand },
};
</script>